<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; 2022 CGT Media Group. All rights reserved</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->