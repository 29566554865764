<!-- Start Fun Facts Area -->
<section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-youtube"></i>
                    </div>
                    <h3><span class="count">50+</span></h3>
                    <p>Channel Youtube</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-people"></i>
                    </div>
                    <h3><span class="count">5,000,000+ </span></h3>
                    <p>Subscribers</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-eye-alt"></i>
                    </div>
                    <h3><span class="count">10,000,000,000+</span></h3>
                    <p>Views</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-youtube-play"></i>
                    </div>
                    <h3><span class="count">5+</span></h3>
                    <p>Gold Play Button</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->
