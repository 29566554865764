<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/{{logo}}" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">
                        <li><a routerLink="/">Home</a></li>

                        <li><a routerLink="/about-us">About Us</a></li>

                        <li class="nav-item"><a routerLink="contact-us" class="nav-link">Contact</a></li>
                    </ul>
                </div>

                <div class="mr-auto others-option">
                    <ul class="navbar-nav">
                        <li data-toggle="modal" data-target="#myModal2">
                            <div class="side-menu">
                                <span class="bar-1"></span>
                                <span class="bar-2"></span>
                                <span class="bar-3"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->